<template>
  <div class="table-responsive">
    <table class="table table-bordered">
      <thead>
      <tr>
        <th>№</th>
        <th>Баркод</th>
        <th>ФИО</th>
        <th>ОП/Уровень</th>
        <th>Предметы</th>
        <th>Язык</th>
        <th>Телефон</th>
        <th>Дата заявки</th>
        <th>Квитанция об оплате</th>
        <th>Действия</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(request, requestIndex) in requests"
          :key="requestIndex">
        <td>{{requestIndex+1}}</td>
        <td>{{request.barcode}}</td>
        <td>{{request?.last_name}} {{request?.first_name}} {{request?.middle_name}}</td>
        <td>
          {{request?.education_speciality_name}}/{{request?.study_level}}
        </td>
        <td>
          <table class="table table-sm">
            <tbody>
            <tr>
              <th>Предметы</th>
              <th>Кредит ects</th>
              <th>Сумма</th>
            </tr>
            <tr v-for="(discipline, disciplineIndex) in request.disciplines" :key="disciplineIndex">
              <td>{{discipline.education_discipline_name}}</td>
              <td>{{discipline.credit}}</td>
              <td>{{Math.round(discipline.sum)}}</td>
            </tr>
            <tr>
              <th>Всего</th>
              <th>{{request.disciplines.reduce((sum, d)=>+d.credit+sum, 0)}}</th>
              <th>{{request.disciplines.reduce((sum, d)=>Math.round(+d.sum)+sum, 0)}}</th>
            </tr>
            </tbody>
          </table>
        </td>
        <td>{{request.language}}</td>
        <td>{{request.phone}}</td>
        <td>{{convertTimestampToDate(request.created_at)}}</td>
        <td>
          <div v-if="request?.file">
            <a :href="`https://back.uib.kz/uploads/${request.file}`" target="_blank">
              Квитанция
            </a>
          </div>
          <div v-else>Не прикреплен</div>
        </td>
        <td>
          <div class="my-1">
            <Button label="Подтвердить" class="p-button-success" :disabled="changingStatus"
                    @click="changeRequestStatus(request.student_difference_request_id, 1)"/>
          </div>
          <div class="my-1">
            <Button label="Отклонить" class="p-button-danger" :disabled="changingStatus"
                    @click="changeRequestStatus(request.student_difference_request_id, 2)"/>
          </div>
        </td>

      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

export default {
  name: "DifferenceSentRequest",
  props: ['requests', 'changeRequestStatus', 'convertTimestampToDate'],
}
</script>

<style scoped>

</style>