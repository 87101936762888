<template>
  <div class="container-fluid p-3">

    <PrimePreloader v-if="loading"/>

    <div v-else>
      <h5 class="m-3">Заявления на академ разницу</h5>
      <TabView>
        <TabPanel header="Отправленные">
          <DifferenceSentRequest
              :requests="officeRegistrationDifferenceStudentRequest_form.requests.filter(i=>i.status == 0)"
              :changeRequestStatus="changeRequestStatus"
              :convertTimestampToDate="convertGraduateWorkTimestampToDate"/>
        </TabPanel>
        <TabPanel header="Подтвержденные">
          <DifferenceConfirmedRequest
              :requests="officeRegistrationDifferenceStudentRequest_form.requests.filter(i=>i.status == 1)"
              :convertTimestampToDate="convertGraduateWorkTimestampToDate"/>
        </TabPanel>
        <TabPanel header="Отказанные">
          <DifferenceRejectedRequest
              :requests="officeRegistrationDifferenceStudentRequest_form.requests.filter(i=>i.status == 2)"
              :changeRequestStatus="changeRequestStatus"
              :convertTimestampToDate="convertGraduateWorkTimestampToDate"/>
        </TabPanel>
      </TabView>
    </div>

  </div>
</template>

<script>
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
import {convertGraduateWorkTimestampToDate} from "@/utils/helpers/convertGraduateWorkTimestampToDate";
import DifferenceSentRequest
  from "@/components/office-registration/difference/DifferenceSentRequest.vue";
import DifferenceConfirmedRequest
  from "@/components/office-registration/difference/DifferenceConfirmedRequest.vue";
import DifferenceRejectedRequest
  from "@/components/office-registration/difference/DifferenceRejectedRequest.vue";

export default {
  name: "OfficeRegistrationStudentRequest",
  components: {
    DifferenceSentRequest,
    DifferenceConfirmedRequest,
    DifferenceRejectedRequest
  },
  data() {
    return {
      loading: true,
      requestStatuses: {
        0: 'Оправлено',
        1: 'Подтверждено',
        2: 'Отклонено'
      },
      changingStatus: false
    }
  },
  computed: {
    ...mapState('officeRegistrationDifferenceStudentRequest', ['officeRegistrationDifferenceStudentRequest_form']),
  },
  methods: {
    ...mapActions('officeRegistrationDifferenceStudentRequest', ['GET_STUDENT_DIFFERENCE_REQUEST', 'PUT_STUDENT_DIFFERENCE_REQUEST_STATUS']),
    convertGraduateWorkTimestampToDate,
    async changeRequestStatus(id, status) {
      this.changingStatus = true
      const res = await this.PUT_STUDENT_DIFFERENCE_REQUEST_STATUS({id, status})
      if (res) {
        await this.GET_STUDENT_DIFFERENCE_REQUEST()
        this.$message({text: `Успешно. Статус ${this.requestStatuses[status]}`})
      }
      this.changingStatus = false
    }
  },
  async mounted() {
    await this.GET_STUDENT_DIFFERENCE_REQUEST()
    this.loading = false
  }
}
</script>

<style scoped>

</style>