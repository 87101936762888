<template>
  <div class="table-responsive">
    <table class="table table-bordered">
      <thead>
      <tr>
        <th>№</th>
        <th>Баркод</th>
        <th>ФИО</th>
        <th>ОП/Уровень</th>
        <th>Язык</th>
        <th>Телефон</th>
        <th>Дата заявки</th>
        <th>Предметы</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(request, requestIndex) in requests"
          :key="requestIndex">
        <td>{{requestIndex+1}}</td>
        <td>{{request.barcode}}</td>
        <td>{{request?.last_name}} {{request?.first_name}} {{request?.middle_name}}</td>
        <td>
          {{request?.education_speciality_name}}/{{request?.study_level}}
        </td>
        <td>{{request.language}}</td>
        <td>{{request.phone}}</td>
        <td>{{convertTimestampToDate(request.created_at)}}</td>
        <td>
          <table class="table table-sm">
            <tbody>
            <tr>
              <th>Предметы</th>
              <th>Кредит ects</th>
              <th>Кафедра</th>
              <th>Преподаватель</th>
            </tr>
            <tr v-for="(discipline, disciplineIndex) in request.disciplines" :key="disciplineIndex">
              <td>{{discipline.education_discipline_name}}</td>
              <td>{{discipline.credit}}</td>
              <td>{{discipline.uib_departments_name}}</td>
              <td>
                {{discipline?.pps_data_lastname}} {{discipline?.pps_data_firstname}}
              </td>
            </tr>
            </tbody>
          </table>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

export default {
  name: "DifferenceConfirmedRequest",
  props: ['requests', 'convertTimestampToDate'],
}
</script>

<style scoped>

</style>